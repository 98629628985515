.clients__name {
  padding: 7rem 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
}

.cn__header {
  p {
    font-size: 5rem;
    font-weight: 600;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    letter-spacing: 0.125rem;
    line-height: 1.5;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
    text-align: center;
    // margin-bottom: 10rem;
  }
}

.cn__bigContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.cn__container {
  img {
    margin-bottom: 4rem;
    padding: 0.5rem;
    height: 15rem !important;
    width: 15rem !important;
    border-radius: 100%;
    border: 5px solid #184088;
    object-fit: contain;
    background-color: #f0fffd;
    margin-right: 3.5rem;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    &:hover {
      border: 5px solid #f9556d;
    }
  }
}

@media screen and (max-width: 900px) {
  .cn__header {
    font-size: 4.7rem;
  }
}

@media screen and (max-width: 460px) {
  .cn__container {
    img {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .cn__header {
    font-size: 3.5rem;
  }
}
