.contactUs {
  padding: 7rem 4.5rem;
  background: transparent;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cu__header {
  p {
    font-size: 5rem;
    font-weight: 600;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    letter-spacing: 0.125rem;
    line-height: 1.5;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
  }
}

.contactUs__container {
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cu__container__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15rem;
}

.cu__containerleft__info {
  p {
    &:nth-child(1) {
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 2rem;
      color: #e7e7e6;
    }

    &:nth-child(2) {
      font-size: 2rem;
      text-align: center;
      width: 40ch;
      color: #e7e7e6;
      margin-bottom: 2rem;
    }
  }
}

.cu__containerleft__info2 {
  display: flex;
  flex-direction: column;
}

.fas .fa-phone-square-alt {
  color: #0ebff2;
}

.cu__phone,
.cu__mail,
.cu__address {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}

.cu__phone {
  margin-top: 6rem;
}

.cu__phone {
  p {
    font-size: 2rem;
    color: #e7e7e6;
  }
}

.cu__mail {
  margin-top: 6rem;
  p {
    font-size: 2rem;
    color: #e7e7e6;
  }
}

.cu__address {
  p {
    font-size: 2rem;
    color: #e7e7e6;
  }
}

.cu__container__right {
  border: none;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  background-color: #d1dfdd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    padding: 2rem 3rem;
    border-radius: 5px;
    border: none;
    box-shadow: 0 5px 5px #dbd7d7;
    width: 100%;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    &:focus {
      outline: solid 2px #f9556d;
    }
  }

  textarea {
    padding: 1rem 2rem;
    border-radius: 5px;
    border: none;
    font-size: 1.6rem;
    box-shadow: 0 5px 5px #dbd7d7;
    width: 100%;
    &:focus {
      outline: solid 2px #f9556d;
    }
  }
}

#submit-b {
  background: transparent;
  color: #0ebff2;
  font-weight: bold;
  border-radius: 5px;
  border: 2.5px solid #0ebff2;
  box-shadow: 0 5px 5px #dbd7d7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem 1.5rem 2rem;
  cursor: pointer;
  transition: transform 150ms ease-out;
  width: 50%;
  align-self: center;
  font-size: 2rem;
  margin-top: 2rem;
}

#submit-b::after {
  content: url("/assets/arrow_forward.svg");
  margin-left: 1rem;
}

#submit-b:hover::after {
  transform: translateX(5px);
}

#submit-b:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1050px) {
  .cu__container__left {
    margin-right: 0;
  }

  .contactUs__container {
    flex-direction: column;
  }

  .cu__container__right {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .cu__container__left {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 900px) {
  .cu__header {
    font-size: 4.7rem;
  }

  .cu__containerleft__info {
    p {
      &:nth-child(1) {
        font-size: 2.9rem;
      }

      &:nth-child(2) {
        font-size: 1.7rem;
      }
    }
  }

  #submit-b {
    width: 60%;
  }
}

@media screen and (max-width: 690px) {
  .cu__header {
    font-size: 3.5rem;
  }

  .cu__container__right {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .cu__containerleft__info {
    p {
      &:nth-child(1) {
        font-size: 2.9rem;
      }

      &:nth-child(2) {
        font-size: 1.7rem;
        width: 35ch;
      }
    }
  }

  #submit-b {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .contactUs {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .cu__container__right {
    width: 90%;
  }
}

@media screen and (max-width: 320px) {
  #submit-b {
    width: 100%;
  }
}
