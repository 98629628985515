footer {
  padding: 5rem 4.5rem;
  background: transparent;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;

  .footer__nav {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .fnav__header {
      font-size: 5rem;
      margin: 5rem 0;
      letter-spacing: 0.25rem;
      background: linear-gradient(268deg, #b445fc, #0ebff2);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      letter-spacing: 0.125rem;
      line-height: 1.5;
      text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
    }

    ul {
      display: flex;
      flex-direction: row;
      margin-bottom: 5rem;
    }
  }

  .fnav__text--container {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 10rem;
    margin-bottom: 0rem;

    .fnav__text {
      font-size: 1.6rem;
      color: #e7e7e6;
      cursor: pointer;
      text-align: center;
    }
  }
}

@media screen and (max-width: 900px) {
  .fnav__header {
    font-size: 4.7rem;
  }
}

@media screen and (max-width: 550px) {
  .fnav__header {
    font-size: 3.5rem;
  }
}
