.project__counters {
  padding: 10rem 4.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
}

.pc__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  justify-content: center;
  border: solid 5px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(268deg, #b445fc, #0ebff2 45%);
  background-origin: border-box;
  background-clip: border-box;
  box-shadow: 2px 1000px 1px #14151a inset;
  margin-bottom: 5rem;
  .pc__image {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .pc__counter {
    font-size: 4rem;
    color: #e7e7e6;
    margin-bottom: 2rem;
  }

  .pc__text {
    font-size: 2rem;
    color: #e7e7e6;
    margin-bottom: 6rem;
  }
}
