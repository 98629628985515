header.sticky {
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: #14151a;
  box-shadow: 0 0px 5px 1px #0f0f0f;
}

header {
  width: 100%;
  padding: 0.5rem 4.5rem;
  position: absolute;

  .header__navbar {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    .logo__container {
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo {
        object-fit: cover;
        height: 5rem;
        width: 5rem;
        margin-top: 0.5rem;
        // border-radius: 5px;
      }
    }
    .nav__links {
      display: flex;
      align-items: center;
      li {
        margin-right: 5rem;
        justify-self: flex-end;

        &:last-child {
          margin-right: 0;
        }
        a {
          color: $fontcolor-blackscreen;
          font-size: $nav-font-size;
          letter-spacing: $letter-spacing;
          border-bottom: 2px solid transparent;
          padding: 0.5rem;
          &:hover {
            padding: 0.5rem;
            border-bottom: 2px solid $secondary-color;
          }
        }
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;

  .line1 {
    width: 25px;
    height: 3px;
    background-color: #0ebff2;
    margin: 5px;
    border-radius: 8px;
    transition: 0.3s ease;
  }
  .line2 {
    width: 16px;
    height: 3px;
    background-color: #0ebff2;
    margin: 5px;
    border-radius: 2px;
    transition: 0.3s ease;
  }
  .line3 {
    width: 8px;
    height: 3px;
    background-color: #0ebff2;
    margin: 5px;
    border-radius: 2px;
    transition: 0.3s ease;
  }
}

.toggle {
  .line1 {
    width: 25px;
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    width: 25px;
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

@media screen and (max-width: 2560px) {
  header {
    .header__navbar {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 991px) {
  .nav__links {
    position: absolute;
    right: 0px;
    height: 100vh;
    position: fixed;
    top: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    background-color: $primary-color;
    transform: translateX(100%);
    transition: transform 500ms ease-in;
    padding: 2em 5em 10em 5em;
    z-index: 5;
    transition: none;
    li {
      opacity: 0;
      margin-right: 0 !important;
    }
  }

  .header__navbar .hamburger {
    display: block;
    z-index: 6;
  }

  .nav-active {
    transform: translateX(0%);
  }

  .display-off {
    display: none;
  }

  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translate(50px);
    }
    to {
      opacity: 1;
      transform: translate(0px);
    }
  }
}

@media screen and (max-width: 626px) {
  .nav__links {
    width: 90%;
  }
}
