.clients__feedback {
  padding: 7rem 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
}

.fa-quote-left {
  font-size: 3rem;
  margin-bottom: 2rem;
  fill: linear-gradient(268deg, #b445fc, #0ebff2);
}

.cf__header {
  p {
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: 0.25rem;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    letter-spacing: 0.125rem;
    line-height: 1.5;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
    text-align: center;
  }
}

.cf__container {
  margin-top: 10rem;
  // background-color: #14151a;
  padding: 1rem;
  // border: solid 5px transparent;
  // background-image: linear-gradient(
  //     rgba(255, 255, 255, 0),
  //     rgba(255, 255, 255, 0)
  //   ),
  //   linear-gradient(268deg, #b445fc, #0ebff2 45%);
  // background-origin: border-box;
  // background-clip: border-box;
  // box-shadow: 2px 1000px 1px #14151a inset;
  height: 375px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // border-radius: 25px;

  &:hover {
    .cf__container__image {
      img {
        border: 3px solid #f9556d;
      }
      p {
        color: #f9556d;
      }
    }

    .cf__container__info {
      p {
        &:nth-child(1) {
          color: #f9556d;
        }
      }
    }

    .fa-quote-left {
      color: #f9556d;
    }
  }
}

.cf__container__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    padding: 0.5rem;
    height: 10rem !important;
    width: 10rem !important;
    border-radius: 100%;
    border: 3px solid #0ebff2;
    object-fit: contain;
    margin-top: 2rem;
    margin-bottom: 2rem;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    background-color: #ffffff;
  }
  p {
    font-size: 2.1rem;
    color: #0ebff2;
    margin-top: 2rem;
    text-align: center;
    font-weight: bold;
  }
}

.cf__container__info {
  display: flex;
  flex-direction: row;
  width: 350px;
  text-align: center;
  p {
    font-size: 1.8rem;
    color: #ebebf5;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    line-height: 1.2;
  }
}

@media screen and (max-width: 900px) {
  .cf__header {
    font-size: 4.7rem;
  }

  .cf__container__image {
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 550px) {
  .cf__header {
    font-size: 3.5rem;
  }
  .cf__container {
    width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .cf__container {
    width: 100%;
    height: 100%;
  }
}

.slick-slide img {
  display: inline-block !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #0ebff2 !important;
  // margin: 1rem !important;
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #0ebff2 !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

@media screen and (max-width: 1000px) {
  .cf__container {
    flex-direction: column;
    height: auto !important;
  }

  .cf__container__info {
    padding: 1.5rem;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }
}

@media screen and (max-width: 950px) {
  .slick-prev {
    left: 90px !important;
  }

  .slick-next {
    right: 90px !important;
  }
}

@media screen and (max-width: 775px) {
  .slick-prev {
    left: 180px !important;
  }

  .slick-next {
    right: 180px !important;
  }
}

@media screen and (max-width: 590px) {
  .slick-prev {
    left: 240px !important;
  }

  .slick-next {
    right: 240px !important;
  }
}

@media screen and (max-width: 470px) {
  .slick-prev {
    left: 270px !important;
  }

  .slick-next {
    right: 270px !important;
  }
}
